'use strict';

Gri.module({
  name: 'header-alpha',
  ieVersion: null,
  $el: $('.header-alpha'),
  container: '.header-alpha',
  fn: function () {

    // region variables
    const $this = this.$el;
    let $layout = $('.layout');
    let $menuButton = $this.find('.menu-button');
    let $menu = $('.menu-wrapper');
    let $backButton = $menu.find('.back');
    let windowW = $(window).width();
    // endregion

    // region run
    headerHeightController();
    $(window).on('resize', headerHeightController);
    // endregion

    // region events
    $menuButton.on('click', function () {
      if ($(this).hasClass('clicked')) {
        $(this).removeClass('clicked');
        $layout.removeClass('active');
        $menu.removeClass('active');
        $menu.find('.sub').removeClass('active');
        $this.removeClass('slide');
      } else {
        $(this).addClass('clicked');
        $layout.addClass('active');
        $menu.addClass('active');
        $this.addClass('slide');
      }
    });

    if(windowW < 992) {
      $menu.find('a').on('click', function () {
        $(this).next().addClass('active');
      });
    } else {
      $menu.find('> .menu > li > a, .sub').mouseover(function () {
        if($(this).parents('li').find('.sub').length > 0) {
          let headerHeight = $this.outerHeight();
          let subHeight = $this.find('.sub').outerHeight();
          $this.find('.bg').height(headerHeight + subHeight);
          $(this).parents('li').addClass('active');
        }
      }).mouseleave(function () {
        let headerHeight = $this.outerHeight();
        $this.find('.bg').height(headerHeight);
        $(this).parents('li').removeClass('active');
      });
    }

    $backButton.on('click', function () {
      $menu.find('.sub').removeClass('active');
    });
    // endregion

    // region events functions

    // endregion

    // region functions
    function headerHeightController() {
      let headerH = $this.outerHeight();
      $('.layout').css('padding-top', headerH);
    }
    // endregion

  }
});
